import React from 'react'

const CatCard = ({ item }) => {
  return (
    <a
      href={item.link}
      className="p-2 flex items-start rounded-lg hover:bg-gray-100 text-gray-600 hover:text-gray-900"
    >
      <div className="flex-shrink-0 flex items-center justify-center h-7 w-7 rounded-md text-dark sm:h-9 sm:w-9">
        <img
          src={require(`../../Assets/icons/${item.icon}.svg`).default}
          className="h-5 w-5"
          alt={item.title}
        />
      </div>
      <div className="ml-4 self-center">
        <p className="font-semibold text-sm Inter">{item.title}</p>
      </div>
    </a>
  )
}

export default CatCard
