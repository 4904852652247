import React from 'react'
import NavList from '../NavList/index'
import PostCard from '../PostCard/index'

export default function Products({header, isAcademy}) {
  return <div>
    <div className={`${isAcademy ? 'pb-16 pt-10 xl:pr-20 lg:pr-16': 'ring-1 ring-black ring-opacity-5 overflow-hidden'}`}>
    {isAcademy ? <div className='p-3 pb-10'>
      <div className='font-bold text-lg'>Our Products <div className='font-thin text-sm max-w-xl'> 
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</div>
        </div>
      </div> : null}
    <div className={isAcademy ? 'relative grid bg-white md:grid-cols-1 lg:grid-cols-3 gap-0' : 'relative grid gap-0 bg-white md:grid-cols-2 lg:grid-cols-4'}>
      {header.navigation[0].info.map((nav, index) => {
        return (
          <div
            className={isAcademy ? '' : 'border-r border-r-gray-300 nav-border'}
            key={index} 
          >
            {nav.type === 'nav-column-list' ? (
              <div className={isAcademy ? '':'p-5'}>
                <h3 className="p-3 font-bold text-lg">
                  {nav.heading}
                </h3>
                {nav.list.map((item, key) => (
                  <NavList
                    item={item}
                    key={'nav-' + key + index}
                  />
                ))}
              </div>
            ) : nav.type === 'nav-column-blog' && !isAcademy ? (
              <PostCard post={nav.blog} />
            ) : null}
          </div>
        )
      })}
    </div>
  </div>
</div>;
}
