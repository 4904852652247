import React, { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import Products from '../Products/index'
import NavList from '../NavList'
import PostCard from '../PostCard'
import CatCard from '../CatCard'
import './Header.css'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header({ common, header, academy }) {
  const [show, setShow] = useState([false, false, false, false])
  const [isMobiShow, setisMobiShow] = useState(false)

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setShow([false, false, false, false])
      }
    }

    document.addEventListener('keyup', handleEscape)
    return () => document.removeEventListener('keyup', handleEscape)
  }, [show])

  return (
    <Popover
      className="relative bg-white"
      onMouseLeave={() => setShow([false, false, false, false])}
    >
      <div
        className="absolute inset-0 shadow z-40 pointer-events-none"
        aria-hidden="true"
      />
      <div className="relative z-30">
        <div className='max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10'>
          <div>
            <a href={common.meta.url} className="flex">
              <span className="sr-only">{common.meta.site}</span>
              <img
                className="urilogo h-8 w-auto sm:h-10"
                src={
                  require(`../../Assets/${common.meta.img.folder}/${common.meta.img.name}`)
                    .default
                }
                alt={common.meta.site}
              />
              { academy ? <div className='flex items-end pb-1 pl-1'><h1 className='font-bold text-lg'>Academy</h1></div> : null}
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClickCapture={() => setisMobiShow(true)}
            >
              <span className="sr-only">Open menu</span>
              <img
                src={require('../../Assets/icons/MenuIcon.svg').default}
                className="h-6 w-6"
                alt="Open Menu"
              />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10">
              <Popover>
                <>
                  <Popover.Button
                    className={classNames(
                      show[0]
                        ? 'text-gray-900 font-bold'
                        : 'text-gray-500 font-semibold',
                      'group bg-white rounded-md inline-flex items-center text-base hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white'
                    )}
                    onMouseOver={() => setShow([true, false, false, false])}
                  >
                    <span>Products</span>
                    <img
                      src={
                        require('../../Assets/icons/ChevronDownIcon.svg')
                          .default
                      }
                      className="ml-2 h-5 w-5 text-gray-900"
                      alt="Products"
                    />
                  </Popover.Button>

                  <Transition
                    show={show[0]}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 -translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-1"
                  >
                    <Popover.Panel className={`max-w-screen-xl mx-auto justify-between items-center hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white overflow-hidden rounded-lg`}>
                      <Products header={header}/>
                    </Popover.Panel>
                  </Transition>
                </>
              </Popover>
              <Popover>
                <>
                  <Popover.Button
                    className={classNames(
                      show[1]
                        ? 'text-gray-900 font-bold'
                        : 'text-gray-500 font-semibold',
                      'group bg-white rounded-md inline-flex items-center text-base hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white'
                    )}
                    onMouseOver={() => setShow([false, true, false, false])}
                  >
                    <span>For D2C</span>
                    <img
                      src={
                        require('../../Assets/icons/ChevronDownIcon.svg')
                          .default
                      }
                      className="ml-2 h-5 w-5 text-gray-900"
                      alt="For D2C"
                    />
                  </Popover.Button>

                  <Transition
                    show={show[1]}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 -translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-1"
                  >
                    <Popover.Panel className={`max-w-screen-xl mx-auto justify-between items-center hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white overflow-hidden rounded-lg`}>
                      <div className="ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-0 bg-white md:grid-cols-2 lg:grid-cols-2">
                          {header.navigation[1].info.map((nav, index) => {
                            return (
                              <div
                                className="border-r border-r-gray-300 nav-border"
                                key={index}
                              >
                                {nav.type === 'nav-column-list' ? (
                                  <div className="p-5">
                                    <h3 className="p-3 font-bold text-lg">
                                      {nav.heading}
                                    </h3>
                                    <div className="relative grid gap-0 bg-white grid-rows-3 grid-flow-col md:grid-cols-2 lg:grid-cols-2">
                                      {nav.list.map((item, key) => (
                                        <NavList
                                          item={item}
                                          key={'nav-' + key + index}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                ) : nav.type === 'nav-column-blog' ? (
                                  <PostCard post={nav.blog} />
                                ) : null}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              </Popover>
              <Popover>
                <>
                  <Popover.Button
                    className={classNames(
                      show[2]
                        ? 'text-gray-900 font-bold'
                        : 'text-gray-500 font-semibold',
                      'group bg-white rounded-md inline-flex items-center text-base hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white'
                    )}
                    onMouseOver={() => setShow([false, false, true, false])}
                  >
                    <span>Resources</span>
                    <img
                      src={
                        require('../../Assets/icons/ChevronDownIcon.svg')
                          .default
                      }
                      className="ml-2 h-5 w-5 text-gray-900"
                      alt="Resources"
                    />
                  </Popover.Button>

                  <Transition
                    show={show[2]}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 -translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-1"
                  >
                    <Popover.Panel className={`max-w-screen-xl mx-auto justify-between items-center hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white overflow-hidden rounded-lg`}>
                      <div className="ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-0 bg-white md:grid-cols-2 lg:grid-cols-4">
                          {header.navigation[2].info.map((nav, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  nav.type === 'nav-column-cat'
                                    ? 'border-r border-r-gray-300 flex flex-col'
                                    : nav.type === 'nav-column-blog'
                                    ? 'nav-blog pl-3 pt-3 pb-3'
                                    : ''
                                }
                              >
                                {nav.type === 'nav-column-list' ? (
                                  <div className="p-5">
                                    <h3 className="p-3 font-bold text-lg">
                                      {nav.heading}
                                    </h3>
                                    {nav.list.map((item, key) => (
                                      <NavList
                                        item={item}
                                        key={'nav-' + key + index}
                                      />
                                    ))}
                                  </div>
                                ) : nav.type === 'nav-column-blog' ? (
                                  <div
                                    className="border border-neutral-200 rounded-lg overflow-hidden h-full hover:border-dark cursor-pointer"
                                    onClick={() => {
                                      window.location.href =
                                        nav.blog?.link || ''
                                    }}
                                  >
                                    <PostCard post={nav.blog} />
                                  </div>
                                ) : nav.type === 'nav-column-cat' ? (
                                  <>
                                    <div className="p-5 grow">
                                      {nav.list.map((item, key) => (
                                        <CatCard
                                          item={item}
                                          key={'cat-' + key + index}
                                        />
                                      ))}
                                    </div>
                                    <div>
                                      <a
                                        href={nav.link.url}
                                        className="inline-flex items-center justify-center px-6 py-6 border border-transparent font-semibold text-primary hover:text-primary-700"
                                      >
                                        {nav.link.name}
                                        <img
                                          src={
                                            require('../../Assets/icons/ArrowRightIcon.svg')
                                              .default
                                          }
                                          className="ml-2 h-5 w-5 text-gray-900"
                                          alt={nav.link.name}
                                        />
                                      </a>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              </Popover>
              <Popover>
                <>
                  <Popover.Button
                    className={classNames(
                      show[3]
                        ? 'text-gray-900 font-bold'
                        : 'text-gray-500 font-semibold',
                      'group bg-white rounded-md inline-flex items-center text-base hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white'
                    )}
                    onMouseOver={() => setShow([false, false, false, true])}
                  >
                    <span>Blogs</span>
                    <img
                      src={
                        require('../../Assets/icons/ChevronDownIcon.svg')
                          .default
                      }
                      className="ml-2 h-5 w-5 text-gray-900"
                      alt="Blogs"
                    />
                  </Popover.Button>

                  <Transition
                    show={show[3]}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 -translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-1"
                  >
                    <Popover.Panel className={`max-w-screen-xl mx-auto justify-between items-center hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white overflow-hidden rounded-lg`}>
                      <div className="ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-0 bg-white md:grid-cols-2 lg:grid-cols-4">
                          {header.navigation[3].info.map((nav, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  nav.type === 'nav-column-cat'
                                    ? 'border-r border-r-gray-300 flex flex-col'
                                    : nav.type === 'nav-column-blog'
                                    ? 'nav-blog pl-3 pt-3 pb-3'
                                    : ''
                                }
                              >
                                {nav.type === 'nav-column-list' ? (
                                  <div className="p-5">
                                    <h3 className="p-3 font-bold text-lg">
                                      {nav.heading}
                                    </h3>
                                    {nav.list.map((item, key) => (
                                      <NavList
                                        item={item}
                                        key={'nav-' + key + index}
                                      />
                                    ))}
                                  </div>
                                ) : nav.type === 'nav-column-blog' ? (
                                  <div
                                    className="border border-neutral-200 rounded-lg overflow-hidden h-full hover:border-dark cursor-pointer"
                                    onClick={() => {
                                      window.location.href =
                                        nav.blog?.link || ''
                                    }}
                                  >
                                    <PostCard post={nav.blog} />
                                  </div>
                                ) : nav.type === 'nav-column-cat' ? (
                                  <>
                                    <div className="p-5 grow">
                                      {nav.list.map((item, key) => (
                                        <CatCard
                                          item={item}
                                          key={'cat-' + key + index}
                                        />
                                      ))}
                                    </div>
                                    <div>
                                      <a
                                        href={nav.link.url}
                                        className="inline-flex items-center justify-center px-6 py-6 border border-transparent font-semibold text-primary hover:text-primary-700"
                                      >
                                        {nav.link.name}
                                        <img
                                          src={
                                            require('../../Assets/icons/ArrowRightIcon.svg')
                                              .default
                                          }
                                          className="ml-2 h-5 w-5 text-gray-900"
                                          alt={nav.link.name}
                                        />
                                      </a>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              </Popover>
            </Popover.Group>
            <div className="flex items-center md:ml-12">
              <a
                href={common.link.url}
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                {common.link.name}
              </a>
              <a
                href={common.call2action.url}
                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-700"
              >
                {common.call2action.name}
              </a>
            </div>
          </div>
        </div>
      </div>

      <Transition
        show={isMobiShow}
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 transition transform origin-top-right md:hidden"
        >
          <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="">
              <div className="flex items-center justify-between ring-1 ring-black ring-opacity-5">
                <div></div>
                <div>
                  <Popover.Button
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                    onClickCapture={() => setisMobiShow(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <img
                      src={require('../../Assets/icons/XIcon.svg').default}
                      className="h-6 w-6"
                      alt="Close Menu"
                    />
                  </Popover.Button>
                </div>
              </div>
              <div className="">
                <nav>
                  <div className="">
                    <Popover.Group
                      as="nav"
                      className="grid grid-flow-row auto-rows-max"
                    >
                      {header.navigation.map((item, key) => (
                        <Popover key={key}>
                          <>
                            <Popover.Button className="border-t border-black border-opacity-5 group text-gray-900 font-semibold bg-white flex items-center text-base h-16 w-full pl-4">
                              <span>{item.name}</span>
                              <img
                                src={
                                  require('../../Assets/icons/ChevronDownIcon.svg')
                                    .default
                                }
                                className="ml-2 h-5 w-5 text-gray-900"
                                alt={item.name}
                              />
                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 -translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 -translate-y-1"
                            >
                              <Popover.Panel className="contents md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                                <div className="m-1 overflow-hidden">
                                  <div className="relative grid gap-0 bg-white md:grid-cols-2 lg:grid-cols-4">
                                    {item.info.map((nav, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={
                                            nav.type === 'nav-column-blog'
                                              ? 'px-3 pb-3'
                                              : ''
                                          }
                                        >
                                          {nav.type === 'nav-column-list' ? (
                                            <div className="p-3">
                                              <h3 className="p-3 font-bold text-lg">
                                                {nav.heading}
                                              </h3>
                                              {nav.list.map((item, key) => (
                                                <NavList
                                                  item={item}
                                                  key={'nav-' + key + index}
                                                />
                                              ))}
                                            </div>
                                          ) : nav.type === 'nav-column-blog' ? (
                                            <div className="border border-neutral-200 rounded-lg overflow-hidden h-full">
                                              <PostCard post={nav.blog} />
                                            </div>
                                          ) : nav.type === 'nav-column-cat' ? (
                                            <div className="p-3">
                                              {nav.list.map((item, key) => (
                                                <CatCard
                                                  item={item}
                                                  key={'cat-' + key + index}
                                                />
                                              ))}
                                            </div>
                                          ) : null}
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        </Popover>
                      ))}
                    </Popover.Group>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
