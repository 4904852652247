import React from 'react'

const NavList = ({ item }) => {
  return (
    <a
      href={item.link}
      className="p-3 flex items-start rounded-lg hover:bg-gray-100"
    >
      <div className="flex-shrink-0 flex items-center justify-center h-9 w-9 rounded-md bg-dark text-white sm:h-9 sm:w-9">
        <img
          src={require(`../../Assets/icons/${item.icon}.svg`).default}
          className="h-6 w-6"
          alt={item.title}
        />
      </div>
      <div className="ml-4">
        <p className="font-bold text-sm text-gray-900">{item.title}</p>
        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
      </div>
    </a>
  )
}

export default NavList
