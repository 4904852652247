import React from 'react'

const PostCard = ({ post }) => {
  return (
    <>
      <div key={post.title} className="flex flex-col">
        <div className="flex-shrink-0">
          <img
            className="h-48 w-full object-contain"
            src={post.heroImage}
            alt=""
          />
        </div>

        <div className="flex-1 bg-white px-6 pt-5 pb-6 flex flex-col justify-between min-h-full">
          <div className="flex-1">
            {post.category && (
              <p className="text-sm font-semibold text-indigo-600">
                <a href={post.category.href} className="hover:underline">
                  {post.category.name}
                </a>
              </p>
            )}
            <a href={post.link} className="block mt-2">
              <p className="text-lg font-semibold text-gray-900 hover:underline cursor-pointer">
                {post.title}
              </p>
            </a>
            <p className="mt-3 text-sm text-gray-500">{post.description}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostCard
