import React from 'react'

export default function Footer({ footer }) {
  return (
    <footer className={'bg-' + footer.bgColor} aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="flex flex-col-reverse lg:flex-row xl:gap-2">
          <div
            className={
              'md:basis-2/3 flex flex-col md:flex-row gap-8 pt-8 md:pt-0'
            }
          >
            {footer.navigations.map((navs, index) => (
              <div key={index}>
                {navs.map((nav) => (
                  <div className="pt-4 pb-6" key={nav.category}>
                    <h3 className="text-sm font-bold text-dark-light text-opacity-75 tracking-wider uppercase Inter">
                      {nav.category}
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {nav.links.map((item) => (
                        <li key={item.name} key={item.name}>
                          <a
                            href={item.url}
                            className="text-dark-extralight hover:text-white text-s"
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="md:basis-1/3 mt-8 xl:mt-16">
            <h1 className="text-6xl font-bold text-dark-light text-opacity-75 tracking-wider">
              {footer.heading}
            </h1>
            <h3 className="mt-8 text-sm font-bold text-dark-light tracking-wider uppercase Inter text-opacity-75">
              {footer.title}
            </h3>
            <p className="mt-4 text-base text-gray-300">{footer.description}</p>
            <form className="mt-8 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                {footer.email.lable}
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400 Inter"
                placeholder={footer.email.placeholder}
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full bg-primary-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-semibold Inter text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                  {footer.email.button}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {footer.social.map((item) => (
              <a
                key={item.name}
                href={item.url}
                className="text-gray-400 hover:text-gray-300"
              >
                <span className="sr-only">{item.name}</span>
                <img
                  src={require(`../../Assets/icons/${item.icon}.svg`).default}
                  className="h-6 w-6"
                  alt={item.name}
                />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1 Inter">
            &copy;{' ' + footer.copyright}
          </p>
        </div>
      </div>
    </footer>
  )
}
