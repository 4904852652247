import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Footer from 'components/Footer'
import Header from 'components/Header'
import header from 'data/header.json'
import footer from 'data/footer.json'

import './Layout.css'
 
const Layout = (props) => {
  // Populate Academy links in header
  const data = useStaticQuery(
    graphql`
      query FeaturedArticles {
        allMarkdownRemark{
          nodes {
            headings {
              depth
              value
            }
            tableOfContents
            frontmatter {
              icon
              link
              title
              type
              category
              description
              featured
              heroImage
              tags
            }
          }
        }
      }
    `
  )

  const { nodes } = data.allMarkdownRemark
  const resourcesList = nodes
    .filter((node) => {
      if (node.frontmatter.link === 'index' && node.frontmatter.type==='academy') {
        return node
      }
    })
    .map((node) => {
      return {
        ...node.frontmatter,
        link: `/academy/${node.frontmatter.category}/`,
      }
    })

  const blogArticles = []
  const productArticles = []
  
  nodes.filter((node)=>{
    if(node.frontmatter.type === 'blog'){
      const {frontmatter} = node
      const newLink = `/blog/${node.frontmatter.link}/`
      const blog = {
        type: 'nav-column-blog',
        blog: {
          title: frontmatter.title,
          description: frontmatter.description,
          heroImage: frontmatter.heroImage,
          category: {
            name: frontmatter.tags[0].replace(/-/g, ' '),
            href: `/blog/tags/${frontmatter.tags[0]}`
          },
          link: newLink
        }
      }
      blogArticles.push(blog)
      if(frontmatter.tags[0] === 'case-studies')
        productArticles.push(blog)
    }
  })

  const academyArticles = nodes
    .filter((node) => {
      if (node.frontmatter.featured && node.frontmatter.link != 'index' && node.frontmatter.type === 'academy')
        return node
    })
    .map((node) => {
      const newLink = `/academy/${node.frontmatter.category}/${node.frontmatter.link}`
      return {
        type: 'nav-column-blog',
        blog: {
          ...node.frontmatter, 
          link: newLink,
        },
      }
    })
  header.navigation.forEach((object, index) => {
    if (object.name === 'Resources') {
      object.info[0].list = resourcesList
      object.info = [...object.info, ...academyArticles]
    }
    else if(object.name === 'Blogs') {
      object.info = [...object.info, ...blogArticles]
    }
    else if(object.name === 'Products') {
      object.info = [...object.info, productArticles[0]]
    }
  })

  return (
    <div className="Layout">
      <Helmet>
        <title>
          Uzr.ai | Customer Data & Marketing Platform for D2C brands
        </title>
        <meta
          name="title"
          content="Uzr.ai | Customer Data & Marketing Platform for D2C brands"
        />
        <meta
          name="description"
          content="description: 5,000+ D2C brands that use uzr.ai's platform to manage, analyze & monetize customers. We are a complete CDP with Data Management, Personalization, AMP Email Marketing, & more!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://uzr.ai" />
        <meta
          property="og:title"
          content="Uzr.ai | Customer Data & Marketing Platform for D2C brands"
        />
        <meta
          property="og:description"
          content="description: 5,000+ D2C brands that use uzr.ai's platform to manage, analyze & monetize customers. We are a complete CDP with Data Management, Personalization, AMP Email Marketing, & more!"
        />
        <meta
          property="og:image"
          content={require('Assets/images/lottie.webp').default}
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://uzr.ai" />
        <meta
          property="twitter:title"
          content="Uzr.ai | Customer Data & Marketing Platform for D2C brands"
        />
        <meta
          property="twitter:description"
          content="description: 5,000+ D2C brands that use uzr.ai's platform to manage, analyze & monetize customers. We are a complete CDP with Data Management, Personalization, AMP Email Marketing, & more!"
        />
        <meta
          property="twitter:image"
          content={require('Assets/images/lottie.webp').default}
        />
      </Helmet>
      <Header header={header} common={props.common} academy={props.academy}/>
      <React.StrictMode>{props.children}</React.StrictMode>
      {!props.academy ? <Footer footer={footer}/> : null}
    </div>
  )
}

export default Layout
